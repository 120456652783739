<template>
    <!-- profile css 사용 -->
    <div class="container">
        <img
            src="/img/home/no-show@3x.png"
            :style="{
                margin: '0 -3rem',
                maxWidth: `${$store.state.app.width}px`,
                width: `${$store.state.app.width}px`,
            }"
        />

        <footer-box
            :submit-text="'환불 및 보상 규정 상세보기'"
            :submit-cb="() => $router.push('/etc/refund-policy')"
            :submit-disabled="false"
        >
        </footer-box>
    </div>
</template>

<script>
import FooterBox from '@/components/common/FooterBox'

export default {
    name: 'EtcNoShow',
    components: {
        FooterBox,
    },
    data() {
        return {}
    },
}
</script>
