var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("img", {
        style: {
          margin: "0 -3rem",
          maxWidth: _vm.$store.state.app.width + "px",
          width: _vm.$store.state.app.width + "px"
        },
        attrs: { src: "/img/home/no-show@3x.png" }
      }),
      _c("footer-box", {
        attrs: {
          "submit-text": "환불 및 보상 규정 상세보기",
          "submit-cb": function() {
            return _vm.$router.push("/etc/refund-policy")
          },
          "submit-disabled": false
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }